import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import Truncate from 'react-truncate'


const BlogPage = ({ data }) => (
  
  <Layout>
      <h1 className="text-center text-3xl sm:text-6xl text-joker">BLOG</h1>
{data.allStrapiArticle.edges.map(document => (
<div className="px-4">
           
       <div className="w-full font-light text-xl text-joker px-6 italic mt-3 ">
                <span className="text-black dark:text-gray-400 mr-2 text-xl italic">{document.node.date}</span>
                <Link className="hover:text-black dark:text-gray-200" to={`/${document.node.slug}`}>{document.node.title}</Link>
            </div>
            <p className="text-gray-800 font-sans text-base px-6 mb-5 mt-2 leading-relaxed">
            <Truncate className="dark:text-gray-200" lines={3} ellipsis={<span>... <Link className="hover:text-black text-joker dark:text-gray-500" to={`/${document.node.id}`}>Mehr Lesen</Link></span>}>
            
           {document.node.content}
            
            
            </Truncate>
            </p>
            <div className="hr-dotted"></div>

</div>
))}
  </Layout>
)

export default BlogPage

export const pageQuery = graphql`  
  query IndexQuery {
    allStrapiArticle {
      edges {
        node {
          id
          slug
          image {
            childImageSharp {
              fixed(width: 400, height: 250) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          title
          content
          date(formatString: "DD-MM-Y")
        }
      }
    }
  }
`